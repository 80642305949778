import React, {useRef, useState} from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'
import home from './elements/home'
import { calenderApiDetail } from '../API/request/calender/calenderApiDetail.interface'
import { init2 } from '../../../@WUM/core/form-serilizer/seriliaze/Formserialize'
import { config, output } from './configuration/mapping'
import { notify_save } from '../../../@WUM/core/component/const/notification.service'
import { error } from '../../../@WUM/core/component/const/error.services'
import { btn_back } from '../../../@WUM/core/component/const/btn_back'
import { handleSubmitForm } from '../../../@WUM/core/component/const/handleSubmitForm'
import { Form } from 'react-bootstrap'
import IntlMessage from '../../../@WUM/core/component/const/IntlMessage'
import { calender } from '../API/request/calender/calender'
import { useSelector } from 'react-redux'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DigitalSignature, { SignaturePad } from 'react-digital-signature'
import {SpinnerJSX} from "../../../@WUM/core/component/const/Spinner";
import {
    ResponseFetchAGDDataset, ResponseFetchDataset,
    ResponseFetchKDDataset,
    ResponsefetchUserDataset
} from "../../serviceauftrag/API/response/calender/calender";
import UniModal from "../../../@WUM/core/Elements/Modal";
import {UniModalHeader} from "../../../@WUM/core/Elements/Modal/Elements/UniModalHeader";
import {UniModalFooter} from "../../../@WUM/core/Elements/Modal/Elements/UniModalFooter";
import DynamikModal from "../../../@WUM/core/component/const/DynamikModal";

const PageNew = (props: { value: any }) => {
    const Store = useSelector((state: any) => state)
    const history = useNavigate()
    const ref = useRef<SignaturePad>()
    const ref2 = useRef<SignaturePad>()
    const Engineer = ResponsefetchUserDataset('engineer');
    const Locksmith = ResponsefetchUserDataset('locksmith');
    const Consultant = ResponsefetchUserDataset('consultant');
    const Admin = ResponsefetchUserDataset('admin');
    const [kdId, setKdId] = useState('1')
    const KDLocal = ResponseFetchKDDataset('kd',kdId.split('-')[0].trim())
    const AGD = ResponseFetchAGDDataset('agd',kdId)
    const [waitStore,SetWaitStore] = useState(false)
    const handleCLear = (e:any) =>{
        e.preventDefault()
        /* @ts-ignore */
        ref.current?.clear()
    }
    const handleCLear2 = (e:any) =>{
        e.preventDefault()
        /* @ts-ignore */
        ref2.current?.clear()
    }
    const [currentTutorial, setCurrentTutorial] = useState<calenderApiDetail[]>(
        []
    )
    /*  const [element,setElement] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalData, setModalData] = React.useState([]);*/

    const [validated, setValidated] = useState(false)
    /*  const [seo_title, setseo_title] = useLocalStorage("seo_title", "");
  const [seo_description, setseo_description] = useLocalStorage("seo_description", "");*/

    let mappingFetch = [
        'objects','PumpeWC','MotorWC','electronicsWC','SensorWC','ootherSCWC','softwareWC','CompleteSystemWC',
        'PumpeCC','MotorCC','electronicsCC','SensorCC','ootherSCCC','softwareCC','CompleteSystemCC',
        'Application','Symptom','Country','Environment','Producer','Action'
    ]



    for(let x =0;x<mappingFetch.length;x++) {
        ResponseFetchDataset(mappingFetch[x],SetWaitStore)

    }

    /**
     * Sendet Formular Daten an die API
     * @param status
     * @param id
     * @param form
     */
    const updateData = (status: boolean, id: any = 0, form: any) => {
        /*
         *  Wichtig zum Rausholen der Datan aus Formular und senden
         *  Das Interface muss immer angepasst werden
         */
        let Data = init2(form)

        let reauestData: calenderApiDetail[] & config = Object.assign(
            {},
            currentTutorial,
            output(Data)
        )

        /*
         *  Senden der Erhaltenen Daten an das Frontend
         */
        calender
            .updatePostNew(reauestData, id)
            .then((response) => {
                setCurrentTutorial({ ...currentTutorial })
                notify_save()
                if(response.ID !== undefined && response.ID !== null) {
                    setTimeout(
                        () => (window.location.href = '/serviceauftragGrundfos/Detail/'+response.ID),
                        3000
                    )
                } else {
                    setTimeout(
                        () => (window.location.href = '/serviceauftragGrundfos/overview/1'),
                        3000
                    )
                }


            })
            .catch((e) => {
                error(e)
            })
    }

    if (Engineer?.length > 0) {
        if (Engineer?.length > 0 &&  Locksmith?.length > 0 && Admin?.length > 0) {
            let fetchUser = [
                ...Engineer,
                ...Locksmith,
                ...Admin
            ]
            localStorage.setItem('AllUSerSa', JSON.stringify(fetchUser))
        } else
        if (Engineer?.length > 0 && Locksmith?.length > 0 && Admin?.length > 0) {
            let fetchUser = [
                ...Engineer,
                ...Locksmith,
                ...Admin
            ]
            localStorage.setItem('AllUSerSa', JSON.stringify(fetchUser))
        }
        if (Engineer?.length > 0 && Locksmith?.length > 0) {
            let fetchUser = [
                ...Engineer,
                ...Locksmith
            ]
            localStorage.setItem('AllUSerSa', JSON.stringify(fetchUser))
        } else {
            localStorage.setItem('AllUSerSa', JSON.stringify(Engineer))
        }
    }

    if (Consultant?.length && Admin?.length > 0)
    {
        let fetchUser = [
            ...Consultant,
            ...Admin
        ]
        localStorage.setItem('AllUSerSaSB', JSON.stringify(fetchUser))
    }

    const trim = () => {
        let signatureText = document.getElementById('signature') as HTMLSelectElement;
        if(signatureText !== null && signatureText !== undefined) {
            signatureText.value = (ref.current?.toDataURL() !== undefined ? ref.current?.toDataURL() : '')
        }
    }


    if (KDLocal?.length > 0 && AGD?.length > 0  && waitStore === true) {
    return (
        <div>
            <Form
                id={'form001'}
                className={'eventsCalender'}
                noValidate
                validated={validated}
                style={{width: '80%',margin: '0 auto'}}
                onSubmit={(e) =>
                    handleSubmitForm(e, updateData, setValidated, 0)
                }
            >
                <Tabs
                    defaultActiveKey="home"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    key={'tabindex'}
                >
                    <Tab
                        eventKey="home"
                        title={
                            <IntlMessage
                                messageId="common.sa.saG_Number"
                                Store={Store}
                            />
                        }
                        key={'allgemein'}
                        id={'allgemein'}
                        style={{
                            backgroundColor: '#EFEFEF',
                            padding: '15px',
                            marginTop: '-15px'
                        }}
                    >
                        {home('new', Store,setKdId)}
                        <div className="App">
                            <Row>
                                <Col>
                                    <h3>Kunde</h3>
                                    <Button variant="primary" onClick={(e)=>handleCLear(e)}>Löschen</Button>
                                    {/*<Button
                                        variant="success"
                                        type={'button'}
                                        className={'ml-3'}
                                        onClick={()=>trim()}
                                    >
                                        Bestätigen
                                    </Button>*/}
                                    <UniModal Button={{Text:'Bestätigen',OnClick:trim,Variant:'success',Class:'ml-3',Type:"button"}}>
                                        <UniModalHeader elements={'Unterschrift zwischengespeichert'} />
                                        <UniModal.Body elements={'Unterschrift wurde zwischengespeichert. Zum Speichern des Formulars bitte "Speichern" klicken.'} />
                                        <UniModalFooter elements={''} />
                                    </UniModal>
                                    <br />
                                    <br />
                                    <DigitalSignature
                                        signaturePadRef={ref}
                                        canvasProps={{
                                            style:{
                                                background:"white",
                                                border:"1px solid black",
                                                width:'395px',
                                                height:'125px'
                                            }
                                        }}
                                    />
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Control as="textarea" id={'signature'} name={'signature'} rows={3} style={{display:'none'}}/>
                                    </Form.Group>

                                </Col>
                            </Row>

                        </div>
                    </Tab>
                </Tabs>
                <div style={{ backgroundColor: '#EFEFEF', padding: '15px' }}>
                    {btn_back(history, 'common.back', '', Store)}
                    <Button
                        variant="success"
                        style={{ float: 'right', marginRight: '15px' }}
                        type={'submit'}
                    >
                        <IntlMessage messageId="common.save" Store={Store} />
                    </Button>
                    <br style={{ clear: 'both' }} />
                </div>
            </Form>
        </div>
    )
    } else {
        return(
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}


export default PageNew
