import React, {useRef, useState} from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import Button from 'react-bootstrap/Button'
import { useNavigate, useParams } from 'react-router-dom'
import home from './elements/home'
import { init2 } from '../../../@WUM/core/form-serilizer/seriliaze/Formserialize'
import { config, output } from './configuration/mapping'
import { error } from '../../../@WUM/core/component/const/error.services'
import { btn_back } from '../../../@WUM/core/component/const/btn_back'
import { handleSubmitForm } from '../../../@WUM/core/component/const/handleSubmitForm'
import { Form } from 'react-bootstrap'
import IntlMessage from '../../../@WUM/core/component/const/IntlMessage'
import { ResponseCalenderDetail } from '../API/response/calender/calender'
import { calenderApiDetail } from '../API/request/calender/calenderApiDetail.interface'
import { calender } from '../API/request/calender/calender'
import { useSelector } from 'react-redux'
import { SpinnerJSX } from '../../../@WUM/core/component/const/Spinner'
import DigitalSignature, { SignaturePad } from 'react-digital-signature'
import {
    ResponseFetchAGDDataset,
    ResponseFetchDataset,
    ResponseFetchKDDataset, ResponsefetchUserDataset
} from "../../serviceauftrag/API/response/calender/calender";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {ApiRoot} from "../../../@WUM/core/RestFullApi/api";
import UniModal from "../../../@WUM/core/Elements/Modal";
import {UniModalHeader} from "../../../@WUM/core/Elements/Modal/Elements/UniModalHeader";
import {UniModalFooter} from "../../../@WUM/core/Elements/Modal/Elements/UniModalFooter";
import DynamikModal from "../../../@WUM/core/component/const/DynamikModal";

const PageDetail = (props: { value: any }) => {
    const Store = useSelector((state: any) => state)
    const { id } = useParams<{ id: string }>()
    const response2 = ResponseCalenderDetail(id)
    const ref = useRef<SignaturePad>()
    const ref2 = useRef<SignaturePad>()
    const Engineer = ResponsefetchUserDataset('engineer');
    const Locksmith = ResponsefetchUserDataset('locksmith');
    const Consultant = ResponsefetchUserDataset('consultant');
    const Admin = ResponsefetchUserDataset('admin');
    const [deactive,SetDeactive] = useState(false)
    const [show, setShow] = useState(false);
    const [start,SetStart] = useState(true)
    const [waitStore,SetWaitStore] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // Aktuelles Datum abrufen
    const date = new Date();

    // Datum in deutsches Format konvertieren
    const dateString = date.toLocaleDateString("de-DE");

    const handleCLear = (e:any) =>{
        e.preventDefault()
        /* @ts-ignore */
        ref.current?.clear()
        let signatureText = document.getElementById('signature') as HTMLSelectElement;
        if(signatureText !== null && signatureText !== undefined) {
            signatureText.value = ''
        }
    }
    const history = useNavigate()
    let mappingFetch = [
        'objects','PumpeWC','MotorWC','electronicsWC','SensorWC','ootherSCWC','softwareWC','CompleteSystemWC',
        'PumpeCC','MotorCC','electronicsCC','SensorCC','ootherSCCC','softwareCC','CompleteSystemCC',
        'Application','Symptom','Country','Environment','Producer','Action'
    ]



    for(let x =0;x<mappingFetch.length;x++) {
        ResponseFetchDataset(mappingFetch[x],SetWaitStore)

    }
    const [kdId, setKdId] = useState('1')
    const KDLocal = ResponseFetchKDDataset('kd',kdId.split('-')[0].trim())
    const AGD = ResponseFetchAGDDataset('agd',kdId)
    ResponsefetchUserDataset('engineer');
    const [currentTutorial, setCurrentTutorial] =
        useState<calenderApiDetail[]>(response2)

    const [validated, setValidated] = useState(false)

    /**
     * Sendet Formular Daten an die API
     * @param status
     * @param id
     * @param form
     */
    const updateData = (status: boolean, id: any, form: any) => {
        /*
         *  Wichtig zum Rausholen der Datan aus Formular und senden
         *  Das Interface muss immer angepasst werden
         */
        let Data = init2(form)

        let reauestData: calenderApiDetail[] & config = Object.assign(
            {},
            currentTutorial,
            output(Data)
        )

        /*
         *  Senden der Erhaltenen Daten an das Frontend
         */
        calender
            .updatePost(reauestData, id)
            .then((response) => {
                setCurrentTutorial({ ...currentTutorial })
                /* notify_save_intvall_reload();*/
                $('#btn_save')[0].setAttribute('disabled', 'disbaled')
                setTimeout(() => window.location.reload(), 3000)
            })
            .catch((e) => {
                error(e)
            })
    }

    if (response2.length > 0 && Consultant?.length && Admin?.length > 0)
    {
        let fetchUser = [
            ...Consultant,
            ...Admin
        ]
        localStorage.setItem('AllUSerSaSB', JSON.stringify(fetchUser))
    }

    if (response2.length > 0 && Engineer?.length > 0) {
        if (response2.length > 0 && Engineer?.length > 0 && Locksmith?.length > 0 && Admin?.length > 0) {
            let fetchUser = [
                ...Engineer,
                ...Locksmith,
                ...Admin
            ]
            localStorage.setItem('AllUSerSa', JSON.stringify(fetchUser))
        } else if (response2.length > 0 && Engineer?.length > 0 && Locksmith?.length > 0 && Admin?.length > 0) {
            let fetchUser = [
                ...Engineer,
                ...Locksmith,
                ...Admin
            ]
            localStorage.setItem('AllUSerSa', JSON.stringify(fetchUser))
        }
        if (response2.length > 0 && Engineer?.length > 0 && Locksmith?.length > 0) {
            let fetchUser = [
                ...Engineer,
                ...Locksmith,
            ]
            localStorage.setItem('AllUSerSa', JSON.stringify(fetchUser))
        } else {
            localStorage.setItem('AllUSerSa', JSON.stringify(Engineer))
        }
    }

    const trim = () => {
        let signatureText = document.getElementById('signature') as HTMLTextAreaElement;
        let signature_date = document.getElementById('signature_date') as HTMLInputElement;
        if(signatureText !== null && signatureText !== undefined) {
            signatureText.value = (ref.current?.toDataURL() !== undefined ? ref.current?.toDataURL() : '')
        }
        if(signature_date !== null && signature_date !== undefined) {
            signature_date.value = dateString
        }
    }

    if(response2.length !== undefined  && response2[0] !== undefined && response2[0].signature !== null && response2[0].signature !== '_'){
        if(deactive === false && start === false) {

        } else {
            ref.current?.fromDataURL(response2[0].signature)
            let signatureText = document.getElementById('signature') as HTMLTextAreaElement;
            if(signatureText !== null && signatureText !== undefined) {
                signatureText.value = response2[0].signature
            }
        }

    }

    const enabledFields = (e:any) => {
        e.stopPropagation();
        e.preventDefault()
        /* @ts-ignore */
        ref.current?.clear()
        SetDeactive(false)
        $(':input').removeAttr('readonly');
        $('select').removeAttr('disabled');
        let signatureText = document.getElementById('signature') as HTMLTextAreaElement;
        if(signatureText !== null && signatureText !== undefined) {
            signatureText.value = '_'
        }

        let agd_adress = document.getElementById('agd_adress') as HTMLTextAreaElement;
        if(agd_adress !== null && agd_adress !== undefined) {
            agd_adress.readOnly = true
        }
        let kd_adress = document.getElementById('kd_adress') as HTMLTextAreaElement;
        if(kd_adress !== null && kd_adress !== undefined) {
            kd_adress.readOnly = true
        }

        let Servicebericht = document.getElementById('Servicebericht') as HTMLTextAreaElement;
        if(Servicebericht !== null && Servicebericht !== undefined) {
            Servicebericht.readOnly = false
        }
        let INTERNEVER = document.getElementById('INTERNEVER') as HTMLTextAreaElement;
        if(INTERNEVER !== null && INTERNEVER !== undefined) {
            INTERNEVER.readOnly = false
        }
        let servicebericht_status = document.getElementById('servicebericht_status') as HTMLSelectElement;
        if(servicebericht_status !== null && servicebericht_status !== undefined) {
            servicebericht_status.removeAttribute('readonly')
            servicebericht_status.removeAttribute('disabled')
        }
        let pruefbericht_status = document.getElementById('pruefbericht_status') as HTMLSelectElement;
        if(pruefbericht_status !== null && pruefbericht_status !== undefined) {
            pruefbericht_status.removeAttribute('readonly')
            pruefbericht_status.removeAttribute('disabled')
        }
        let pdf_city = document.getElementById('pdf_city') as HTMLSelectElement;
        if(pdf_city !== null && pdf_city !== undefined) {
            pdf_city.removeAttribute('readonly')
            pdf_city.removeAttribute('disabled')
        }
        handleClose();

    }

    if (response2.length > 0  && Engineer?.length > 0 && waitStore === true) {
    return (
        <div>
            <Form
                id={'form001'}
                className={'eventsCalender'}
                noValidate
                validated={validated}
                style={{width: '80%',margin: '0 auto'}}
                onSubmit={(e) =>
                    handleSubmitForm(e, updateData, setValidated, id)
                }
            >
                <Tabs
                    defaultActiveKey="home"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    key={'tabindex'}
                >
                    <Tab
                        eventKey="home"
                        title={
                            <IntlMessage
                                messageId="common.sa.saG_Number"
                                Store={Store}
                            />
                        }
                        key={'allgemein'}
                        id={'allgemein'}
                        style={{
                            backgroundColor: '#EFEFEF',
                            padding: '15px',
                            marginTop: '-15px'
                        }}
                    >
                        {home((response2.length !== undefined ? response2[0] : response2), Store,setKdId)}
                        <div className="App">
                            <Row>
                                <Col>
                                    <h3>Kunde</h3>
                                    <Button variant="primary" onClick={(e) => handleCLear(e)}>
                                        Löschen
                                    </Button>
                                    <UniModal Button={{Text:'Bestätigen',OnClick:trim,Variant:'success',Class:'ml-3',Type:"button"}}>
                                        <UniModalHeader elements={'Unterschrift zwischengespeichert'} />
                                        <UniModal.Body elements={'Unterschrift wurde zwischengespeichert. Zum Speichern des Formulars bitte "Speichern" klicken.'} />
                                        <UniModalFooter elements={''} />
                                    </UniModal>
                                    <DynamikModal handlesubmit={enabledFields}
                                                  closeButtonLabel={'Abbruch'} ClassOpenButton={'ml-3'}
                                                  openButtonLabel={'Formular freigeben'}
                                                  saveButtonLabel={'Bestätigen'}
                                                  variantOpenButton={'info'}
                                                  variantSaveButton={'danger'}
                                                  body={<><p>Die Unterschrift wird entfernt und das Formular kann erneut bearbeitet werden. Eine erneute Unterschrift ist notwendig.</p></>} ClassSaveButton={'float-end'}
                                                  handleClose={handleClose} handleShow={handleShow} setShow={setShow} show={show}
                                                  title={'Formular freigeben'}/>
                                    <br/>
                                    <br/>

                                    <DigitalSignature
                                        signaturePadRef={ref}
                                        canvasProps={{
                                            style: {
                                                background: "white",
                                                border: "1px solid black",
                                                width:'395px',
                                                height:'125px'
                                            }
                                        }}
                                    />
                                    {(response2.length !== undefined ?
                                        <div style={{width: '395px'}}>
                                            <Form.Select name={"pdf_city"} id={"pdf_city"}>
                                                <option label={"Bitte Unterschriftsort wählen"}/>
                                                <option value="1">Pumpenwolf: Reichenbach</option>
                                                {(response2[0].ag !== null ?
                                                        <>
                                                            <option value={response2[0].ag.Ort.split(' ')[1]}>Vor
                                                                Ort: {response2[0].ag.Ort.split(' ')[1]}</option>
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                )}
                                                {(response2[0].kd !== null ?
                                                        <>
                                                            <option
                                                                value={response2[0].kd.Ort.split(' ')[1]}>Einsatzadresse: {response2[0].kd.Ort.split(' ')[1]}</option>
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                )}
                                            </Form.Select>
                                        </div>
                                        : <></>)}

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Control as="textarea" id={'signature'} name={'signature'} rows={3} style={{display:'none'}}/>
                                        <Form.Control id={'signature_date'} name={'signature_date'} style={{display:'none'}}/>
                                    </Form.Group>

                                </Col>
                            </Row>

                        </div>
                    </Tab>
                </Tabs>
                <div  style={{ backgroundColor: '#EFEFEF', padding: '15px' }}>
                    <Row className={"justify-content-between"}>
                        <Col sm={1}>
                        {btn_back(history, 'common.back', '', Store)}
                        </Col>
                        <Col sm={1}> <Button
                            variant="success"
                            id={'btn_print'}
                            style={{ marginRight: '15px' }}
                            type={'button'}
                            href={ApiRoot()+'sa/grund/print/'+id+'/'}
                            target={'new_blank'}
                        > <IntlMessage messageId="common.print" Store={Store} /> </Button>
                        </Col >
                        <Col sm={1}>
                        <Button
                            variant="success"
                            id={'btn_save'}
                            style={{ float: 'right', marginRight: '15px' }}
                            type={'submit'}
                        >
                            <IntlMessage messageId="common.save" Store={Store} />
                        </Button>
                        </Col>
                </Row>
                <br style={{ clear: 'both' }} />
                </div>
            </Form>
        </div>
    )
    } else {
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}

export default PageDetail
