import { calenderApiType } from './calenderApi.interface'
import { requests } from '../../../../../@WUM/core/RestFullApi/api'
import { calenderApiDetail } from './calenderApiDetail.interface'

export const calender = {
    getPosts: (
        limit: string,
        searchValue: string
    ): Promise<calenderApiType[]> =>
        requests.get(`sa/grundfosa/all/${limit}/${searchValue}`),
    getAPost: (id: string): Promise<calenderApiDetail[]> =>
        requests.get(`/sa/${id}/`),
    createPost: (post: calenderApiType): Promise<calenderApiType> =>
        requests.post('posts', post),
    updatePost: (post: any, id: number): Promise<calenderApiDetail> =>
        requests.post(`/sa/grundfosa/${id}/`, post),
    updatePostNew: (post: any, id: number): Promise<calenderApiDetail> =>
        requests.post(`/sa/grundfosa/${id}/`, post),
    deletePost: (id: string): Promise<void> =>
        requests.delete(`/sa/${id}/`),
    fetchDataset: (type: string): Promise<calenderApiDetail[]> =>
        requests.get(`pw/fetch/${type}/`),
    fetchAGDDataset: (type: string): Promise<calenderApiDetail[]> =>
        requests.get(`${type}/fetch/`)
}
